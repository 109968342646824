import React, { createContext, useState, useEffect } from 'react'

const defaultThemeState = { theme: 'light', setTheme: () => {} }

export const ThemeContext = createContext(defaultThemeState)

export default function ThemeSwitcher({ children }) {
  const [theme, setTheme] = useState(() => {
    const hour = new Date().getHours()

    if (hour < 6 || hour >= 18) {
      return 'dark'
    }

    return 'light'
  })

  useEffect(() => {
    if (theme === 'light') {
      document.documentElement.setAttribute('data-theme', 'light')
    } else {
      document.documentElement.setAttribute('data-theme', 'dark')
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
